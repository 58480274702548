#mgnlhp {
    position: absolute;
    top: 0;
    left: -9000px;
}

section.Section.form-bg,
section.Section.order-form-bg,
form > .text {
    text-align: center;
}

.form .text.error {
    display: inline-block;
    background-color: #dd6600;
    padding: 0.5em 1em;
}

h3.form-subtitle {
    padding: 2.5rem 0;
}

h3.form-error-title {
    padding: 0.5em 0;
    margin: 0;
}

ul.form-ul {
    display: inline-block;
    list-style: none;
    padding: 0.5em 0;
    margin: 0;
}

section.Section.form-bg form,
section.Section.order-form-bg form{
    text-align: left;
}

section.-onload-hidden {
    max-height: 0;
    transition: max-height 0.5s linear;
}

section.-is-open {
    max-height: none;
    transition: max-height 0s linear;
}

.editMode section.-onload-hidden {
    max-height: none;
}

.Section.form-bg > .container > h2 {
    margin: 0;
}

@media screen and (min-width: 768px) {
    .Section.form-bg > .container > h2 {
        font-size: 5.4em;
        line-height: 7.5rem;
        font-weight: 100;
        text-indent: 0;
    }
}

.form-wrapper > form {
    position: relative;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

form > fieldset {
    position: relative;
    border: 0;
    margin: 0;
    padding: 5rem 0 5rem 33%;
    border-bottom: 1px solid #fff;
}
/*
form > fieldset:first-of-type {
    padding: 2.5rem 0 5rem 33%;
} */

@media screen and (max-width: 767px) {
    form > fieldset {
        padding: 3.75rem 0 2.5rem 0;
    }
    /*
    form > fieldset:first-of-type {
        padding: 1.25rem 0 2.5rem 0;
    } */
}

fieldset > legend {
    font-family: "Raleway", sans-serif;
    font-size: 2.4em;
    font-weight: 300;
    line-height: 2.5rem;
    min-height: 3.75rem;
    padding: 1.25rem 0;
    display: block;
}

fieldset.mod > legend {
    display: none;
}

form > fieldset.-with-title > .form-row > label {
    display: none;
}

form > fieldset:last-of-type {
    border-bottom: 0 none;
    padding-bottom: 0;
    margin-bottom: 2.5rem;
}

.form-row {
    text-align: left;
    display: inline-block;
    margin-bottom: 1.25rem;
}

.form-row,
.button-wrapper {
    width: 100%;
    clear: both;
}

.form-row.form-row-50 {
    width: 49%;
}

.form-row.form-row-right {
    float: right;
}

.form-row.form-row-33 {
    width: 33%;
}

.form-row.form-row-66 {
    width: 65%;
}

.form-row p{
    margin: 6px 0px 6px 0px;
}

.form-row > label {
    position: absolute;
    left: 0;
    width: 32%;
    height: 100%;
    display: block;
}

@media screen and (max-width: 767px) {
    .form-row > label {
        position: relative;
        left: auto;
        width: 100%;
        height: auto;
    }
}

@media screen and (min-width: 768px) {
    fieldset.-with-title > h2 {
        position: absolute;
        top: 5rem;
        left: 0;
        width: 33%;
        height: 0;
    }
    /*
    fieldset.-with-title:first-of-type > h2 {
        top: 2.5rem;
    } */
}

fieldset > h2 {
    margin-bottom: 2.5rem;
}

fieldset > .text > h2,
fieldset > h2,
.form-row > label > span {
    color: #fff;
    line-height: 2.5rem;
    padding: 0.6rem 0;
    font-size: 2.4em;
    font-weight: 300;
    min-height: 3.75rem;
    display: inline-block;
    background: transparent;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 767px) {
    .form-row > label > span {
        font-size: 2em;
        height: auto;
    }
}

.form-item {
    position: relative;
    margin-top: 1em;
}

fieldset.mod .form-item {
    display: inline-block;
    padding-right: 3rem;
}

.form-item:first-child,
.form-item:first-of-type {
    margin-top: 0;
}

.form-item > label {
    font-size: 1.6em;
    line-height: 2.5rem;
    min-height: 3.75rem;
    padding: 0.6rem 0.8em 0.65rem 0.8em;
    display: block;
}

.form-item > input[type="checkbox"],
.form-item > input[type="radio"]{
    position: absolute;
    opacity: 0;
    width: 3.75rem;
    height: 3.75rem;
    -webkit-appearance: none;
    border-radius: 0;
    border: 0;
}

.form-item > input[type="checkbox"] + label,
.form-item > input[type="radio"] + label {
    padding-left: 5rem;
    cursor: pointer;
}

.form-item > input[type="checkbox"] + label:before {
    content: "";
    width: 3.75rem;
    height: 3.75rem;
    display: block;
    border: 1px solid #fff;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
}

.form-item > input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    top: 0.5rem;
    left: 1.5rem;
    width: 1rem;
    height: 2rem;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #fff;
    display: block;
    border-radius: 1px;
    transform: rotate(45deg);
}

.form-item > input[type="radio"] + label:before {
    content: "";
    width: 3.75rem;
    height: 3.75rem;
    display: block;
    border: 1px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}

.form-item > input[type="radio"]:checked + label:after {
    content: "";
    position: absolute;
    top: 1.375rem;
    left: 1.375rem;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    display: block;
    border-radius: 50%;
}

.form-row > span {
    font-size: 1.6em;
    padding: 1.25rem 0;
    line-height: 2.5rem;
    display: block;
    font-family: "Raleway", sans-serif;
    font-style: italic;
    font-weight: 300;
}

.form-row em { /* Error-field */
    display: none;
}

select:focus {
    outline: 0;
}

/*
select:invalid{
    box-shadow: inset 0 0 2px red;
} */


.form-row select,
.form-row option {
    background: transparent;
    min-height: 3.75rem;
    padding: 0.6rem 0.8em 0.65rem 0.8em;
    color: #fff;
    -webkit-appearance: none;
    border: 0;
    border-radius: 0;
}

.form-row-select > fieldset > select {
    width: calc(100% + 4em);
    height: 3.75rem;
    -webkit-appearance: none;
    cursor: pointer;
    font-size: 1.6em;
}

.form-row-select > fieldset:hover > select:focus,
.form-row-select > fieldset:hover > select:focus option {
    background-color: #fff;
    color: #29515C;
}

.form-row-select > label > span {
    position: relative;
    background: transparent;
    color: #fff;
    width: auto;
    border: 0;
    padding-right: 0.8em;
}

.form-row-select > fieldset {
    font-size: 1em;
    position: relative;
    display: inline-block;
    overflow: hidden;
    z-index: 1;
    border: 1px solid #fff;
    border-radius: 3px;
    padding-right: 3em;
    cursor: pointer;
}

.form-row-select > fieldset:before {
    position: absolute;
    content: "";
    top: 50%;
    right: 1.6rem;
    width: 1.2em;
    height: 1.2em;
    transform: translate3d(50%, -75%, 0) rotate(135deg);
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: #fff;
    transition: transform 0.2s linear;
}

.form-row-select > fieldset:hover:before {
    transform: translate3d(50%, -50%, 0) rotate(135deg);
}

.form-row-select.-is-focused > fieldset:hover {
    background-color: #fff;
}

.form-row-select.-is-focused > fieldset:hover:before {
    border-color: #29515C;
}

.form-row > input {
    border: 0;
    outline: 0;
    -webkit-appearance: none;
}

form.-is-submit input:invalid,
form.-is-submit textarea:invalid{
    box-shadow: inset 0 0 2px #dd6600;
}

.form-row > textarea,
.form-row > input[type="search"],
.form-row > input[type="url"],
.form-row > input[type="date"],
.form-row > input[type="file"],
.form-row > input[type="text"],
.form-row > input[type="email"],
.form-row > input[type="password"] {
    position: relative;
    min-height: 3.75rem;
    line-height: 2.5rem;
    font-size: 1.6em;
    margin: 0;
    padding: 0.6rem 0.8em 0.65rem 0.8em;
    width: 100%;
    color: #29515C;
    border-radius: 3px;
    border: 0;
    background-color: #fff;
    opacity: 1;
}

:-moz-placeholder,
::-webkit-input-placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
    font-family: "Raleway", sans-serif;
    color: #333;
    font-style: italic;
    font-weight: 300;
}

.form-row > textarea:focus,
.form-row > input[type="text"]:focus,
.form-row > input[type="email"]:focus,
.form-row > input[type="password"]:focus {
    background-color: #fff;
    opacity: 1;
}

.form-bg {
    background-color: #29515C;
    color: #fff;
}

.order-form-bg {
    background-color: #00829E;
    color: #fff;
}

p.required {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    display: none;
}

p.required:last-of-type {
    display: block;
}

.button-wrapper {
    text-align: right;
    padding-top: 5rem;
}

@media screen and (max-width: 767px) {
    .button-wrapper {
        padding-top: 2.5rem;
    }
}

.button-wrapper > input[type="submit"] {
    font-family: Ubuntu, sans-serif;
    font-size: 2.4em;
    line-height: 2.5rem;
    font-weight: 400;
    min-height: 5rem;
    padding: 1.25rem 1em;
    text-transform: uppercase;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #fff;
}

.button-wrapper > input[type="submit"]:hover {
    color: #29515C;
    background-color: #fff;
    border-color: #fff;
    transition: border-color 0.2s linear, color 0.2s linear, background-color 0.2s linear;
}

i.check-icon {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1em;
}

i.check-icon:after {
    content: "";
    position: absolute;
    top: -0.25em;
    left: 0;
    width: 1rem;
    height: 2rem;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #29515C;
    display: block;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form-info {
    text-align: center;
    display: none;
    vertical-align: middle;
    margin-bottom: 2.5rem;
}

.order-form-bg .form-info {
    display: inline-block;
}

.form-pack {
    font-size: 3em;
    font-family: "ubuntu", sans-serif;
    line-height: 3.75rem;
    display: inline-block;
}

@media screen and (max-width: 767px) {
    .form-pack {
        font-size: 2.4em;
        line-height: 3.75rem;
        padding: 0.3125em 0;
    }
}

.form-price {
    font-size: 2.4em;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    background-color: #0099bb;
    padding: 0 0.5em;
    margin: 0 0.5em;
    line-height: 3.75rem;
    display: inline-block;
}