.box-wrapper {
    display: flex;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 5rem -1rem 0 -1rem;
}

.editMode .box-wrapper {
    display: block;
}

.box {
    display: flex;
    justify-content: space-between;
    width: calc(50% - 2rem);
    margin: 0 1rem 2rem 1rem;
    transition: background-color 0.2s linear;
}

.box:last-child {
    margin-bottom: 0;
}

.box-wrapper .box {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
}

@media screen and (max-width: 450px) {
    .box-wrapper .box {
        width: calc(100% - 2rem);
    }
}

.box-icon {
    display: block;
    width: 4.5rem;
    min-width: 4.5rem;
    max-width: 4.5rem;
    height: 4.5rem;
    margin-right: 1rem;
    overflow: hidden;
}

.box-icon img,
.box-icon svg {
    width: 100%;
    height: auto;
}

.box-icon svg * {
    transition: color 0.2s linear;
}

.box-icon svg > g > * {
    stroke-width: 2px;
}

.box-text {
    flex-grow: 2;
    text-align: inherit;
}

/* Team Box */

.box.team {
    display: block;
    margin-bottom: 5rem;
}

@media screen and (min-width: 451px) {
    .box.team {
        padding-right: 3rem;
    }
}

@media screen and (max-width: 450px) {
    .box.team {
    }
}

.box-intro-text {
    margin-top: 0.5em;
}

.box-intro-text > p {
    margin-bottom: 0.25em;
}

.box-intro-text.emphasize > p {
    background-color: #fff;
    display: inline;
    font-style: italic;
}

.box-head {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.box-pic {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    margin-right: 2rem;
    background-position: center center;
    background-size: cover;
}