/* View */
.View {
	position: absolute;
	width: 100%;
	overflow: hidden;
	left: 0;
	top: 0;
	right: 0;
	z-index: 3;
}

.editMode .View {
	position: relative;
	overflow: visible;
	top: auto;
}

@media screen and (max-width:450px) {
	.View.-no-scroll {
		/* block scroll effect on iOS but force to scroll on top */
		position: fixed;
	}
}

/* Sections */
/*
.Section {
	padding: var( --sectionSplitterHeight ) var( --pageGutterWidth );
	position: relative;
}
.Section > .container {
	width: 100%;
	max-width: var( --pageWidth );
	margin: var( --sectionSplitterHeight ) auto;
	position: relative;
	z-index: 2;
}

.Section > .container:first-of-type {
	margin-top: 0;
}

.Section > .container:last-of-type {
	margin-bottom: 0;
} */