.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    stroke: currentColor;
}

.-icon {
    line-height: 0;
}