@charset "utf-8";
/**
 * ======= HoW tO wrItE CoDE =======
 * | variable       : --likeThis   |
 * | media queries  : --like-this  |
 * | block, cmpnt.  : .Like-this   |
 * | element        : .like-this   |
 * | modifier       : .-like-this  |
 * =================================
 */


/* Layout configuration */
@import "normalize.css";
@import url(config/default.css);
@import url(config/layout.css);
@import url(modifiers/align.css);
@import url(modifiers/icons.css);
@import url(modifiers/layout.css);
@import url(modifiers/text.css);
@import url(frontools/widgets/carousel.css);
@import url(sylon/menu.css);
@import url(sylon/layout.css);
@import url(sylon/teaser.css);
@import url(sylon/boxes.css);
@import url(sylon/form.css);
@import url(sylon/table.css);


/* Modules */


/**
 * Variables
 */
:root {
	/* Text - default */
	--defaultFontFamily: sans-serif;
	--webfontFamily: 'Raleway', sans-serif;
	--fontSize: 15px;
	--defaultTextColor: #333;

	/* Page - default, layout */
	--pageBackground: #E8E8E8;
	--pageWidth: 100%;
	--pageGutterWidth: 15px;
	--sectionSplitterHeight: 80px;
}
