/* Default settings */
*, *:before, *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: inherit;
}

/*
  Repeating the background mostly makes sense in the <body>. Otherwise, people usually want the image and preferably its center (not the top-right corner)
*/
*:not(body) {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

[draggable] {
    user-select: none;
    /* Required to make elements draggable in old WebKit */
    user-drag: element;
}

html, body {
    /* Text */
    font-family: var( --defaultFontFamily );
    font-size: var( --fontSize );
    line-height: 1.5;
    font-weight: 400;
    color: var( --defaultTextColor );
    /* stroke: var(--defaultTextColor); */
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    /* Page */
    background: var( --pageBackground );
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );

    /* Blocks */
    box-sizing: border-box;

    /* App */
    -webkit-touch-callout: none;
    /* -webkit-user-select: none; */
    background-attachment: fixed;
}

body.-webfont {
    font-family: var( --webfontFamily );
}

/* Reset normalize.css */
figure {
    margin: 0;
}