.-paragraph {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;

    column-count: auto;
    column-gap: 1.7em;
    column-width: 25em;
    orphans: 3;
    widows: 2;
}