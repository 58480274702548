
.clearfix:after {
    content: "";
    clear: both;
    display: table;
}

.table-content:after {
    content:"";
    clear: both;
    display: table;
}

.table-content {
    position: relative;
    margin-bottom: 5rem;
}


.table-content.comparison-table {
    margin-bottom: 10rem;
}

.table-title {
    line-height: 5rem;
    vertical-align: middle;
    padding: 1.25rem 0;
}

.table-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.table-row {
    /* Opera Mobile */
    background: -o-linear-gradient(bottom, #f5f5f5 1px, white 1px);
    /* Firefox for Android */
    background: -moz-linear-gradient(bottom, #f5f5f5 1px, white 1px);
    /* WebKit browsers */
    background: -webkit-linear-gradient(bottom, #f5f5f5 1px, white 1px);
    /* new syntax: IE10, Firefox, Opera */
    background: linear-gradient(180deg, #f5f5f5 1px, white 1px);
    background-position: 100% 0;
    background-repeat: repeat-x;
    background-size: 100%;
}
.table-row.-no-bgline {
    background: none;
}

.table-row-inner {
    min-height: 5rem;
    padding-right: 5rem;
    display: flex;
}

.table-row p {
    margin: 0;
}

.table-head {
    min-height: 5rem;
}

.table-head,
.table-footer {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

@media screen and (max-width:767px) {
    .table-head {
        flex-wrap: wrap;
        border-bottom: 1px solid #f5f5f5;
    }
    .comparison-table-head:before {
        content: "";
        width: 100%;
        height: 6px;
        bottom: 0;
        position: absolute;
        background-color: #f5f5f5;
        left: 0;
    }
}

.table-footer {
    flex-wrap: nowrap;
}

@media screen and (max-width: 767px) {
    .table-body {
        position: relative;
    }
}

@media screen and (min-width: 768px) {
    .table-body {
        position: static;
    }
}

.table-separator {
    height: 6px;
    background-color: #F5F5F5;
}

@media screen and (min-width: 768px) {
    .item:hover .table-separator,
    .item.-is-selected .table-separator {
        background-color: #e9f0f2;
    }
}

@media screen and (max-width: 767px) {
    .item .table-separator {
        background-color: #e9f0f2;
    }
}
.col-separator {
    height: 6px;
}

@media screen and (max-width: 767px) {
    .table-separator-top {
        display: none;
    }
}

.table-head-inner,
.table-footer-inner {
    display: flex;
    text-align: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .table-head-inner {
        width: 100%;
        justify-content: flex-start;
    }
}

.table-cell-value {
    font-size: 1.6em;
    padding: 1.25rem 0;
    line-height: 2.5rem;
    text-align: center;
    display: block;
    vertical-align: baseline;
}

.table-cell-value.price {
    font-size: 2em;
    font-weight: 400;
}

.table-row-single .table-cell-value {
    position: absolute;
    top: 0;
    right: 5rem;
    height: 100%;
}

.table-cell {
    margin: 0;
    padding: 1.25rem 0;
    line-height: 2.5rem;
}

.table-cell-label {
    font-size: 1.6em;
    padding-right: 1em;
}

.table-cell-label.property-title {
    font-size: 1.6em;
}

.table-cell-label.property-subtitle {
    font-size: 1.6em;
    color: #9B9B9B;
}
.table-cell-label.property-price {
    font-weight: 600;
}
.table-cell-value.property-price {
    font-size: 2em;
    font-weight: 400;
}

.table-desc {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0%;
    height: auto;
    background: #f3f3f3;
    z-index: 999;
    transition: transform 0s linear;
    transform: translateX(100%);
    /* Opera Mobile */
    background: -o-linear-gradient(bottom, #fff 1px, #f3f3f3 1px);
    /* Firefox for Android */
    background: -moz-linear-gradient(bottom, #fff 1px, #f3f3f3 1px);
    /* WebKit browsers */
    background: -webkit-linear-gradient(bottom, #fff 1px, #f3f3f3 1px);
    /* new syntax: IE10, Firefox, Opera */
    background: linear-gradient(180deg, #fff 1px, #f3f3f3 1px);
    background-position: 100% 0;
    background-repeat: repeat-x;
    background-size: 100%;
}

.table-desc.-is-open {
    height: 100%;
    transform: translateX(0%);
    transition: transform 0s 0.2s linear;
}

.table-desc-inner {
    position: relative;
    padding: 1.25em 5em 1.25em 1.25em;
    opacity: 0;
    transition: opacity 0.2s linear;
}

.table-desc.-is-open .table-desc-inner {
    opacity: 1;
    transition: opacity 0.5s linear;
}

.table-desc-open {
    position: absolute;
    width: 5rem;
    height: 5rem;
    top: 0;
    left: -5rem;
    cursor: pointer;
}

.table-desc-open:after {
    content: "...";
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    top: 0.625rem;
    right: 0.625rem;
    background-color: transparent;
    position: absolute;
    font-size: 2em;
    line-height: 2.5rem;
    text-align: center;
    font-family: Ubuntu, sans-serif;
    cursor: pointer;
    transition: background-color 0.5s ease-in-out;
}

.table-desc-open:active:after {
    background-color: #F9F9F9;
}

@media screen and (min-device-width: 768px) {
    .table-desc-open:hover:after {
        background-color: #F9F9F9;
    }
}

.table-desc-close {
    position: absolute;
    width: 5rem;
    height: 5rem;
    top: 0;
    right: 0;
    display: inline-block;
    vertical-align: middle;
}

.desc-close-icon {
    position: relative;
    display: inline-block;
    margin: 0.625rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.2s linear;
}

.desc-close-icon-1 {
    position: absolute;
    content: "";
    height: 1px;
    width: 2rem;
    top: 50%;
    left: 50%;
    margin-left: -1rem;
    background-color: #29515C;
    transform: rotate(45deg);
}

.desc-close-icon-2 {
    position: absolute;
    content: "";
    width: 1px;
    height: 2rem;
    left: 50%;
    top: 50%;
    margin-top: -1rem;
    background-color: #29515C;
    transform: rotate(45deg);
}

.table-button {
    display: block;
}

.carousel-wrapper {
    position: absolute;
    top: 0;
    right: 5rem;
    z-index: 1;
    width: 100%;
    max-width: calc(66%);
}

.carousel-placeholder {
    min-width: calc(66% + 5rem);
}

.common-placeholder {
    min-width: calc(20% + 5rem);
}

@media screen and (max-width: 767px) {
    .carousel-wrapper {
        max-width: calc(25%);
    }

    .carousel-placeholder {
        min-width: calc(25% + 5rem);
    }

    .common-placeholder {
        min-width: calc(25% + 5rem);
    }

}



.Carousel > .items {
}


/* Carousel */

.items {
    display: flex;
    transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
}

.item {
    background-color: transparent;
}

@media screen and (min-width: 768px) {
    .item-content {
        padding: 0 1em;
    }
}

@media screen and (max-width: 767px) {
    .item-content {
        padding: 0;
    }
}

button.table-btn {
    width: 100%;
}

.item-tab {
    position: relative;
    border-bottom: 6px solid transparent;
    text-align: center;
}

.item-tab.item-tab-sm {
    cursor: pointer;
    margin-right: 2em;
}

.item.-is-selected .item-tab,
.item:hover .item-tab,
.item-tab.-active, .item-tab:hover {
    border-bottom: 6px solid #0099BB;
}

.table-col-cell {
    text-align: center;
}

.table-row, .table-col-cell {
    min-height: 5rem;
}

.common-placeholder > .table-col-cell {
    width: 100%;
}

.table-row.-is-closed {
    min-height: 0!important;
}

@media screen and (min-width: 768px) {

    .item-tab-md {
        display: block;
    }

    .item-tab-sm {
        display: none;
    }

    .item:hover .table-col-cell,
    .item.-is-selected .table-col-cell {
        background-color: rgba(0,153,187,0.05);
    }

    .item:hover .table-cell-arrow,
    .item.-is-selected .table-cell-arrow {
        background-color: rgba(0,153,187,0.05);
    }

    .item:hover .table-cell-arrow:before,
    .item.-is-selected .table-cell-arrow:before {
        background: linear-gradient(to right bottom, rgba(0,153,187,0.05) 50%, transparent 50%)
    }

    .item:hover .table-cell-arrow:after,
    .item.-is-selected .table-cell-arrow:after {
        background: linear-gradient(to left bottom, rgba(0,153,187,0.05) 50%, transparent 50%)
    }

    .mobile-col-btn {
        display: none;
    }

    .mobile-col-btn {
        visibility: hidden;
    }
}


@media screen and (max-width: 767px) {

    .item-tab-md {
        display: none;
    }

    .item-tab-sm {
        display: block;
    }

    .item .table-col-cell {
        background-color: rgba(0,153,187,0.05);
    }

    .table-col-btn button {
        display: none;
    }

    .table-col-btn.-disabled {
        visibility: hidden;
    }

    .item .item-tab {
        border-bottom: 6px solid #0099BB;
    }
    .item .table-cell-arrow {
        background-color: rgba(0,153,187,0.05);
    }

    .item .table-cell-arrow:before {
        background: linear-gradient(to right bottom, rgba(0,153,187,0.05) 50%, transparent 50%)
    }

    .item .table-cell-arrow:after {
        background: linear-gradient(to left bottom, rgba(0,153,187,0.05) 50%, transparent 50%)
    }

    .item .table-cell-arrow.-disabled:before,
    .item .table-cell-arrow.-disabled:after {
        visibility: hidden;
    }
}




.table-cell-arrow {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 2.5rem;
    text-align: center;
    color: white;
    background-color: transparent;
    line-height: 2.5rem;
    text-decoration: none;
}

.table-cell-arrow:before {
    content: "";
    position: absolute;
    top: 100%;
    right: 0;
    width: 50%;
    height: 10px;
    background: linear-gradient(to right bottom, transparent 50%, transparent 50%)
}

.table-cell-arrow:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 50%;
    height: 10px;
    background: linear-gradient(to left bottom, transparent 50%, transparent 50%)
}