.header {
    overflow: hidden;
    height: 7.5rem;
    border-bottom: 1px solid #eee;
}

.Section.header {
    padding: 0 8rem;
}

.header .mgnlEditorBar {
    position: absolute!important;
}

.container.container--header {
    padding: 2.5rem 0 2.5rem 15rem;
    text-align: right;
}

.link-icon > svg,
.link-icon > img {
    display: inline-block;
    margin-right: 0.5em;
    width: 1em;
    height: auto;
    transition: stroke 0.2s linear;
}

a.link-icon > svg * {
    stroke: #28515C!important;
}

.submenu-col a.link-icon > svg * {
    stroke: #fff!important;
}

a.link-icon > svg *,
a.link-icon:hover > svg *,
a.link-icon.active > svg * {
    stroke: #0099BB!important;
}

.link-icon > svg > g {
    stroke-width: 5px;
}

.link-icon > svg > circle {
    stroke-width: 0px;
}

.link-label {
    display: inline-block;
}

.footer {
    overflow: hidden;
    height: 7.5rem;
    border-top: 1px solid #eee;
}

.editMode .footer {
    min-height: 20rem;
}

.container.container--footer {
    padding: 2.5rem 8rem 2.5rem 8rem;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .container.container--footer {
        padding: 2.5rem 0rem;
        text-align: center;
        display: flex;
        justify-content: space-between;
    }
}

.header .container a,
.footer .container a {
    font-size: 1.6em;
    line-height: 2.5rem;
    padding: 0 0 0 1em;
    display: inline-block;
}

.footer .container a {
    padding: 0 0.5em;
}

.mainNav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
}

@media screen and (max-width: 450px) {

    .mainNav {
        display: block;
        font-size: 1.2em;
        padding: 2.5rem 0 7.5rem 0;
    }
}

.Menu {
    display: block;
    background-color: #29515C;
    color: #fff;
    clear: both;
}

a,
a:visited {
    color: inherit;
    transition: color 0.2s linear;
    text-decoration: none;
}
p > a,
p > a:visited,
li > a,
li > a:visited {
    color: #0099BB; /* #00829E; */
}

a:hover,
a:active,
p > a:hover,
p > a:active,
li > a:hover,
li > a:active {
    color: #00bbff; /* #0099BB; */
}

a[href*=".pdf"] {
}

a[href*=".pdf"]::before {
    position: relative;
    content: " ";
    width: 8px;
    height: 10px;
    background: url(img/pdf.svg) top left no-repeat transparent;
    background-size: auto 100%;
    display: inline-block;
    vertical-align: baseline;
    margin-right: 0.5em;
    opacity: 0.8;
}

a[href*=".pdf"]:hover::before {
    background-position: top right;
}

.form-section p > a,
.form-section p > a:visited {
    color: #00D1FF;
}

.form-section a:hover,
.form-section a:active,
.form-section p > a:hover,
.form-section p > a:active {
    color: #33DAFF;
}


.container.container--menu {
    padding: 2.5rem 0 0 0;
}

@media screen and (max-width: 450px) {
    .container.container--menu {
        position: absolute;
        top: 7.5rem;
        width: 100%;
        margin: 0;
        padding: 0;
        max-height: calc(100vh - 7.5rem);
    }
}

.container--menu > h2 {
    line-height: 3rem;
}

.submenu {
    color: #fff;
    display: block;
}

.menu-separator,
.submenu-separator {
    width: 4rem;
}

.submenu-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3.75rem;
}


@media screen and (max-width: 450px) {

    .submenu-row {
        padding-left: 0em;
    }
}

@media screen and (max-width: 450px) {

    .submenu-row:before {
        display: none;
    }
}

.submenu-col {
    display: block;
}

.submenu h2.h5 {
    line-height: 3rem;
}

a.menu-link {
    font-size: 1.6em;
    line-height: normal;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: inherit;
    display: inline-block;
    clear: both;
    transition: color 0s linear;
}

@media screen and (max-width: 450px) {

    a.menu-link {
        padding-top: 0.7em;
        padding-bottom: 0.7em;
    }
}

.submenu-col > a.menu-link {
    float: left;
}

a.menu-link.-with-icon {
    position: relative;
    padding-left: 1.7em;
}

a.menu-link.-with-icon svg,
a.menu-link.-with-icon img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

a.menu-link > svg,
a.menu-link > img {
    width: 1.2em;
    height: auto;
    vertical-align: baseline;
}

a.menu-link > svg > g > * {
    stroke-width: 4px;
}

a.menu-link > svg > circle {
    stroke-width: 0;
}

.submenu > h2.h5 {
    opacity: 0.4;
    font-weight: 500;
    line-height: 5rem;
}

.container--header > a.menu-link {
    margin-left: 1em;
}

.container--header > a.menu-link,
.container--header > a.menu-link:visited,
.container--header > a.menu-link > svg > g > * ,
.container--header > a.menu-link:visited > svg > g > * {
    color: #29515C;
}

.container--header > a.menu-link:hover,
.container--header > a.menu-link:active,
.container--header > a.menu-link:hover > svg > g > * ,
.container--header > a.menu-link:active > svg > g > * {
    color: #00bbff; /* #0099BB; */
    stroke: currentColor!important;
}

a.menu-link,
a.menu-link:visited,
a.menu-link > svg > g > * ,
a.menu-link:visited > svg > g > * {
    color: #fff;
    stroke: currentColor!important;
    transition: color 0.2s linear;
}

a.menu-link:hover,
a.menu-link:active,
a.menu-link.active,
a.menu-link:hover > svg > g > *,
a.menu-link:active > svg > g > *,
a.menu-link.active > svg > g > * {
    color: #00D1FF;
    stroke: currentColor!important;
}

.title--logo  {
    position: absolute;
    top: 1rem;
    left: 2rem;
}

.title--logo > img,
.title--logo > svg {
    height: 5.5rem;
    width: auto;
}

#burger {
    float: right;
    margin-top: 1.5rem;
    margin-right: 2rem;
    width: 2rem;
    height: 2rem;
}

#topbar {
    float: right;
}

.mobile-menu-header {
    display: none;
}

@media screen and (max-width: 450px) {
    .mobile-menu-header {
        display: block;
        min-height: 7.5rem;
    }
}

.burger, .cross {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    transition-duration: 0.5s;
    cursor: pointer;
}

.cross {
    opacity: 0;
    display: none;
}

@media screen and (max-width: 450px) {
    .cross {
        display: block;
    }
    .Menu .cross {
        opacity: 0;
        transition: opacity 0.5s 0s linear;
    }
    .Menu.-is-open .cross {
        opacity: 1;
        transition: opacity 0.2s 0.2s linear;
    }
}


.icon-left {
    position: absolute;
    height: 0px;
    width: 1.75rem;
    top: 1.75rem;
}

.icon-left:before{
    position: absolute;
    width: 1.75rem;
    height: 0;
    background-color: transparent;
    content: "";
    top: -0.5rem;
    border-bottom: 1px solid #29515C;
    transition: top 0.2s linear, transform 0.2s linear;
}

.cross .icon-left:before{
    border-bottom: 1px solid #fff;
}

@media screen and (min-device-width:1025px) {
    .burger:hover .icon-left:before{
        top: -1rem;
    }
}

.icon-left:after{
    position: absolute;
    width: 1.75rem;
    height: 0;
    background-color: transparent;
    content: "";
    top: 0.5rem;
    border-bottom: 1px solid #29515C;
    transition: top 0.2s linear, transform 0.2s linear;
}

.cross .icon-left:after{
    border-bottom: 1px solid #fff;
}

@media screen and (min-device-width:1025px) {
    .burger:hover .icon-left:after,
    .cross:hover .icon-left:after {
        top: 0.8rem;
    }
}

.icon-left:hover{
    cursor: pointer;
}


.icon-right {
    transition-duration: 0.5s;
    position: absolute;
    width: 1.75rem;
    top: 1.75rem;
    left: 1.75rem;
}

.icon-right:before{
    position: absolute;
    width: 1.75rem;
    height: 0;
    background-color: transparent;
    content: "";
    top: -0.5rem;
    border-bottom: 1px solid #29515C;
    transition: top 0.2s linear, transform 0.2s linear;
}

.cross .icon-right:before{
    border-bottom: 1px solid #fff;
}

@media screen and (min-device-width:1025px) {
    .burger:hover .icon-right:before,
    .cross:hover .icon-right:before {
        top: -1rem;
    }
}

.icon-right:after{
    position: absolute;
    width: 1.75rem;
    height: 0;
    background-color: transparent;
    content: "";
    top: 0.5rem;
    border-bottom: 1px solid #29515C;
    transition: top 0.2s linear, transform 0.2s linear;
}

.cross .icon-right:after{
    border-bottom: 1px solid #fff;
}

@media screen and (min-device-width:1025px) {
    .burger:hover .icon-right:after {
        top: 0.8rem;
    }
}


.icon-left {
    background: transparent;
}

.burger.open .icon-left:before,
.cross .icon-left:before{
    top: -1rem;
    /* transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px); */
    transform: rotateZ(45deg) scaleX(1.4) translate(0px, 0px);
}

.burger.open .icon-left:after,
.cross .icon-left:after{
    top: 0.8rem;
    /* transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px); */
    transform: rotateZ(-45deg) scaleX(1.4) translate(0px, 0px);
}

.icon-right {
    background: transparent;
}

.burger.open .icon-right:before,
.cross .icon-right:before {
    top: -1rem;
    /* transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px); */
    transform: rotateZ(-45deg) scaleX(1.4) translate(0px, 0px);
}

.burger.open .icon-right:after,
.cross .icon-right:after {
    top: 0.8rem;
    /* transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px); */
    transform: rotateZ(45deg) scaleX(1.4) translate(0px, 0px);
}

.icon-right:hover {
    cursor: pointer;
}


#Menu {
    height: 0px;
    min-height: 0;
    transition: width 0.2s 1s linear, opacity 0.2s linear, left 0.2s 1s linear, margin-bottom 0.2s 1s linear, min-height 0.2s 1s linear, height 1s ease-in-out;
    overflow-y: hidden;
    width: 100%;
    position: relative;
    opacity: 0;
    margin-bottom: 0;
    z-index: 1;
}

@media screen and (max-width: 450px){
    #Menu {
        max-height: none!important;
    }
}

@media screen and (min-device-width: 1025px) {
    #Menu.-is-hovered {
        transition: width 0.2s linear, left 0.2s linear, opacity 0.2s linear, margin-bottom 0.2s linear, min-height 0.2s linear, height 1s ease-in-out;
        min-height: 3px;
        margin-bottom: -3px;
        /* left: 0%;
        width: 100%; */
        opacity: 1;
    }
}

@media screen and (max-device-width: 1024px) {
    #Menu.-is-hovered {
        transition: width 0.2s linear, left 0.2s linear, opacity 0.2s linear, margin-bottom 0.2s linear, min-height 0.2s linear, height 1s ease-in-out;
        min-height: 0;
        opacity: 1;
    }
}

#Menu.-is-open {
    margin-bottom: 0;
    height: 100rem;
    max-height: none;
    /* left: 0%;
    width: 100%; */
    opacity: 1;
}


@media screen and (max-width: 450px) {
    #Menu {
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        overflow-y: scroll;
        transition: left 0.5s linear;
    }

    #Menu.-is-open {
        left: 0%;
    }
}