html, body {
    font-family: Raleway, sans-serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #29515C;
    background-color: #fff;
}

html {
    font-size: 0.625em;
    min-height: 100vh;
}

@media screen and (max-width: 1024px) {
    html {
        font-size: 0.5em;
    }
}

body {
    font-size: 1em;
}

.menu, .submenu {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 6em;
    line-height: 7.5rem;
    margin: 0;
    font-weight: 100;
    text-indent: -2px;
    text-transform: uppercase;
}

h1.home {
    font-size: 7.2em;
    line-height: 7.5rem;
    text-transform: uppercase;
    text-align: center;
    font-family: "Work Sans", sans-serif;
    font-weight: 100;
    text-indent: -5px;
    letter-spacing: 0.1em;
}

h1.overwrite {
    text-transform: none;
}

@media screen and (max-width: 1024px) {
    h1 {
        font-size: 3.6em;
        line-height: 3.75rem;
        margin: 0;
        font-weight: 200;
        text-indent: -2px;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
    }

    h1.home {
        font-size: 3.6em;
        line-height: 3.75rem;
        text-transform: uppercase;
        text-align: center;
        text-indent: 0;
        font-family: "Work Sans", sans-serif;
        font-weight: 200;
        letter-spacing: 0em;
    }
}

@media screen and (max-width: 450px) {
    h1.home {
        font-size: 3.6em;
        line-height: 3.75rem;
        margin: 0;
        font-weight: 200;
        text-indent: -2px;
    }
}


h2 {
    font-size: 3.2em;
    font-weight: 200;
    line-height: 3.75rem;
    margin: 0;
}

.container > h2 {
    margin-bottom: 3.75rem;
}

.container.box-container > h2 {
    margin-top: 1.5rem;
}

h2.form-title {
    margin-bottom: 2.5rem;
}

@media screen and (min-width: 768px) {
    h2.form-title {
        font-size: 5.4em;
        font-weight: 100;
        line-height: normal;
    }

    .container > h2 {
        margin-bottom: 3.75rem;
    }
}

h3 {
    font-size: 2.4em;
    font-weight: 300;
    line-height: 2.5rem;
    margin: 0;
}

.container > h3 {
    margin-bottom: 2.5rem;
}

h3.tab,
h3.mobile-tab {
    font-size: 2em;
    font-family: "ubuntu", sans-serif;
    font-weight: 500;
    line-height: 2.5rem;
    margin: 0;
}

h4 {
    font-size: 2em;
    font-family: Ubuntu, sans-serif;
    font-weight: 500;
    line-height: 2.5rem;
    margin: 0;
}

.container > h4 {
    margin-bottom: 2.5rem;
}

h5,
h2.h5  {
    font-size: 1.8em;
    font-family: Ubuntu, sans-serif;
    font-weight: 500;
    line-height: 2.5rem;
    margin: 0;
}

.container > h5 {
    margin-bottom: 2.5rem;
}

h6 {
    font-size: 1.8em;
    font-family: Ubuntu, sans-serif;
    font-weight: 700;
    line-height: 2.5rem;
    margin: 0;
}

b, strong {
    font-weight: 600;
}

p, .p, ul {
    font-size: 1.6em;
    font-weight: 400;
    line-height: 2.5rem;
}

ul.h-sitemap > li {
    list-style: none;
}

ul.h-sitemap {
    font-size: 1.8em;
    margin: 0;
    padding: 0;
}

ul.h-sitemap > li > a {
    color: #29515c;
    font-weight: 500;
    font-family: Ubuntu, sans-serif;
    text-transform: capitalize;
}

a.u-url,
a.u-url:visited {
    color: #29515c;
}

a.u-url:hover,
a.u-url:active {
    color: #0099BB;
}

p > p,
p > .p,
p > ul,
li > p,
li > .p,
li > ul {
    font-size: 1em;
    margin: 1em 0;
}

li {
    margin-bottom: 1.25rem;
}

p {
    margin: 0 0 2.5rem 0;
}

p.form-p {
    font-size: 2em;
}

em {
    background-color: #fff;
}

.page-headline.home {
    text-align: center;
    width: 100%;
}

.clearfix:after {
    content: "";
    display: table;
}

.Section {
    padding: 0 5rem;
    overflow: hidden;
}

@media screen and (max-width: 767px) {
    .Section {
        padding: 0 2rem;
    }
}

.webhosting-bg {
  background-color: #F2F5F1;
}

p.required {
    position: absolute;
    left: 0;
    bottom: 0;
}


@media screen and (max-width: 450px) {
    .xs-hidden {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .sm-hidden {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .md-hidden {
        display: none;
    }
}

.xs-visible,
.sm-visible,
.md-visible,
.l-visible,
.xl-visible {
    display: none;
}

@media screen and (max-width: 450px) {
    .xs-visible {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .sm-visible {
        display: block;
    }
}

.-with-bottom-line {
    border-bottom: 1px solid #fff;
}

.white-bg {
    background-color: #ffffff;
}

.white-bg.-with-bottom-line {
    border-bottom: 1px solid #eee;
}

.box-container.white-bg {
    background-color: rgba(255,255,255,0.4);
}

.support-bg > .container > .box-container.white-bg {
    background-color: rgba(255,255,255,1);
}

.grey-bg {
    background-color: #F9F9F9;
}

.cloud-bg {
    background-color: #ECEEEB;
}

.company-bg {
    background-color: #f5f5f5;
}

.support-bg {
    background-color: #F6FAFB;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 5rem 0;
}

.container.box-container {
    margin-top: 2.5rem;
    padding: 2rem 2rem 1em 2rem;
}

.container.box-container:first-child {
    margin-top: 0rem;
}

.stage > .container {
    padding: 10rem 0;
    display: flex;
    align-content: stretch;
    flex-wrap: nowrap;
}

@media screen and (max-width: 767px) {
    .stage > .container {
        padding: 5rem 0;
    }
}

.page-icon {
    width: 9rem;
    height: 9rem;
    margin-top: 0.35rem;
    margin-right: 2rem;
    flex-shrink: 0;
}

@media screen and (max-width: 767px) {

    .page-icon {
        width: 5rem;
        height: 5rem;
        margin-right: 1rem;
    }
    .page-icon > svg * {
        stroke-width: 2px;
    }
}

.clearfix:after {
    content: "";
    clear: both;
    display: table;
}

.table-content:after {
    content:"";
    clear: both;
    display: table;
}

button {
    font-family: Ubuntu, sans-serif;
    font-size: 1.8em;
    line-height: 2.5rem;
    font-weight: 400;
    min-height: 3.75rem;
    text-align: center;
    padding: 0.625rem 0.5em;
    text-transform: uppercase;
    color: #00829E;
    background-color: transparent;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #00829E;
    outline: 0;
    box-shadow: 0;
}

.item.-is-selected button,
.item.-is-selected button:hover {
    color: #fff;
    background-color: #00829E;
}

.item.-is-selected button:active {
    color: #00829E;
    background-color: transparent;
}

@media screen and (min-width: 768px) {

    button {
        transition: color 0.2s linear, background-color 0.2s linear;
    }

    button:hover {
        color: #fff;
        background-color: #00829E;
    }
}

.mobile-btn-wrapper {
    position: relative;
}

@media screen and (min-width: 768px) {
    .mobile-btn-wrapper {
        display: none;
    }
}

.table-col-btn {
    padding-top: 1.25rem;
}

.mobile-btn-inner {
    padding-top: 1.25rem;
    height: 6rem;
}

.mobile-btn-button {
    width: auto;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 1em;
    transform: translateX(-50%);
}

.mobile-menu-header .title--logo > svg * {
    fill: #fff!important;
}

/**
Breadcrumb
*/

#breadcrumb {
    font-size: 1.6em;
    border-top: 1px solid #eee;
}

#breadcrumb > .container {
    padding: 1em 0;
}

.breadcrumb-list {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 -0.25em;
}

.breadcrumb-li {
    display: inline-block;
    float: right;
    margin: 0 0.25em;
    padding: 0;
}

.breadcrumb-li.back-arrow {
    display: none;
}

@media screen and (max-width: 450px) {
    .breadcrumb-li {
        float: left;
    }
    .breadcrumb-li.xs-hidden {
        display: none;
    }
    .breadcrumb-li.back-arrow, .breadcrumb-li.xs-visible {
        display: inline-block;
    }
}

.breadcrumb-li > a.breadcrumb-link {
    color: #29515C;
}

.breadcrumb-li > a.breadcrumb-link:hover {
    color: #0099BB;
}

.breadcrumb-li > a.breadcrumb-link.active,
.breadcrumb-li > a.breadcrumb-link.active:hover,
.breadcrumb-li > a.breadcrumb-link.active:active,
.breadcrumb-li> a.breadcrumb-link.active:visited {
    color: #29515C;
    cursor: default;
}