.teaser-wrapper {
    display: flex;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 5rem -1rem 0 -1rem;
}

.editMode .teaser-wrapper {
    display: block;
}

.teaser {
    display: flex;
    justify-content: space-between;
    padding: 2rem 2rem 2rem 1rem;
    border-radius: 3px;
    background-color: #ECEEEB;
    width: calc(50% - 2rem);
    margin: 0 1rem 1rem 1rem;
    transition: background-color 0.2s linear;
}

.support-bg .teaser {
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .teaser:last-child {
       margin-bottom: 0;
    }
}

.teaser-wrapper .teaser {
    flex-direction: row;
    align-items: center;
    text-align: left;
}

@media screen and (max-width: 767px) {
    .teaser-wrapper .teaser {
        width: calc(100% - 2rem);
    }
}

.teaser-icon {
    display: block;
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
    margin-right: 1rem;
    border-radius: 50%;
    overflow: hidden;
}

.teaser-icon img,
.teaser-icon svg {
    width: 100%;
    height: auto;
}

.teaser-icon svg * {
    transition: color 0.2s linear;
}

.teaser-wrapper.horizontal .teaser-icon svg > g > * {
    stroke-width: 2px;
}

@media screen and (max-width: 767px) {
    .teaser-icon svg > g > * {
        stroke-width: 2px;
    }
}

.teaser-icon svg > circle {
    stroke-width: 0px;
    fill: #fff!important;
}

.teaser:hover {
    background-color: #29515C;
    color: #fff;
}

.teaser-text {
    flex-grow: 2;
    text-align: inherit;
}

.teaser-link {
    line-height: 5rem;
}

.teaser-link-label {
    display: none;
}

.teaser-arrow {
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1em;
    margin-left: 0.5em;
}

.teaser-arrow-icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    border-bottom: 1px solid #29515C;
    border-right: 1px solid #29515C;
    transform: rotate(-45deg) translateY(-50%);
    transition: border-bottom 0.2s linear, border-right 0.2s linear;
}

.teaser:hover .teaser-arrow-icon {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}

@media screen and (min-width: 768px) {

    .teaser-wrapper.vertical {
        margin: 0 -1rem;
    }

    .teaser-wrapper.vertical .teaser {
        width: calc(25% - 2rem);
        margin-bottom: 2rem;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 2rem;
        margin: 0 1rem 2rem 1rem;
    }

    .teaser-wrapper.vertical .teaser-icon {
        width: 9rem;
        height: 9rem;
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .teaser-icon {
        margin-right: 2rem;
    }

    .teaser-icon svg {
        stroke-width: 1px;
    }

    .teaser-wrapper.vertical .teaser-link-label {
        display: inline-block;
    }

    .teaser-wrapper.vertical .teaser-arrow-icon {
        width: 0.8rem;
        height: 0.8rem;
        top: 55%;
    }
}
