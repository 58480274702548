/* Align */
.-left {
    justify-content: flex-start;
    text-align: left;
    flex-grow: 0;
}

.-center {
    justify-content: center;
    text-align: center;
    flex-grow: 0;
    /*column-count: 1;*/ /* kill 3D in Chrome */
}

.-right {
    justify-content: flex-end;
    text-align: right;
    flex-grow: 0;
}