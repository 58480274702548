/* Block scroll on Desktop */
@media screen and (max-width:450px) {
    html.-no-scroll, html.-no-scroll > body, .-no-scroll {
        overflow: hidden !important;
        height: 100% !important;
    }
    .-scroll {
        overflow: hidden !important;
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch;
    }
}


/* Layout Modifiers */
.-no-bottom-space {
	margin-bottom: 0;
	padding-bottom: 1px;
}

.-no-top-space {
	margin-top: 0;
	padding-top: 1px;
}